
import { Component, Vue } from "vue-property-decorator";
import SendMsg from "@/lib/sendMsg";

@Component({
  components: {},
})
export default class Login extends Vue {
  private valido = false;
  private usuario = { usr: "", pwd: "" };
  private rules = {
    usrRules: [(v: string) => !!v || "usuário é obrigatório"],
    pwdRules: [(v: any) => !!v || "Senha é obrigatória"],
  };
  executeLogin() {
    this.$auth
      .login({
        data: this.usuario,
        remember: "false",
        fetchUser: true,
        redirect: { path: "/" },
      })
      .then((value) => {
        console.log(value.data);
      })
      .catch((error) => {
        (this.$refs.usr as any).focus();
        this.usuario.usr = "";
        this.usuario.pwd = "";
        this.$auth.logout({ makeRequest: false });
        SendMsg.$emit("Atencao", error.response.data.status);
      });
  }
  created() {
    this.$auth.ready();
  }
}
