import Vue from 'vue';
import VueAxios from 'vue-axios';
import axios from 'axios';

const instance = axios.create({
  baseURL: 'https://server.grupopalmed.com.br:4004/api/',
  timeout: 15000,
  headers: {
    'Accept': 'application/json',
    'Content-type': 'application/json',
    'Access-Control-Expose-Headers': 'Authorization',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*'
  },
});

Vue.use(VueAxios, instance);
