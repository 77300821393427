
import {Component, Vue} from "vue-property-decorator";

@Component({
  components: {},
})
export default class Home extends Vue {
  private visible = null;
  private nomeUsuario = [{nome: String}];

  private itensMenuFeira: Array<object> = [
    ["Pedido", "mdi-basket", "pedidovendafeira"],
    ["Acompanhamento", "mdi-file-document", "acompanhamentofeira"],
  ];
  private itensMenuCashBack: Array<object> = [
    ["Clientes", "mdi-account-details-outline", "clientesrca"],
  ];

  created() {
    this.nomeUsuario = (this.$auth.user() as any).NOME;
  }

  logout() {
    this.$auth.logout({makeRequest: false});
  }
}
