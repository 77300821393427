
import {Component, Vue} from "vue-property-decorator";
import SendMsg from "@/lib/sendMsg";

@Component({
  components: {},
})
export default class AcompanhamentoFeira extends Vue {
  private baseURL = "https://server.grupopalmed.com.br:4004/api/";
  //private baseURL = "http://localhost:4005/api/";

  private tab = 0;
  private formValid = true;
  private keyFindGrid = "";
  private headerGrid = [];
  private itensGrid = [];
  private vlrTotalPed = 0;
  private vlrTotalPedPA = 0;
  private vlrTotalPedPL = 0;
  private vlrTotalPedPR = 0;
  private vlrTotalSaldoPed = 0;
  private exibirInfoInd = false;
  private entriesFilial = [];
  private modelFilial = null;
  private entriesFeira = [];
  private modelFeira = null;
  private entriesFornecedor = [];
  private modelFornecedor = null;
  private entriesCliente = [];
  private modelCliente = null;
  private entriesRCA = [];
  private modelRCA = null;
  private entriesPlanoPag = [];
  private modelPlanoPag = null;
  private entriesEstado = [];
  private modelEstado = null;
  private dialogExcluirPedido = false;
  private modelPedido = null;
  private modelLiberado = true;
  private modelAnalise = true;
  private modelRejeitado = true;
  private modelOrcamento = true;
  private descriptionLimit = 80;
  private numPedidoGestCom = 0;
  private numPedidoWinthor = 0;
  private dates = [];
  private menu = false;

  mounted() {
    this.inicializar();
  }

  inicializar() {
    this.headerGrid = [
      {text: "Editar", value: "EDITAR", align: "center", sortable: false},
      {text: "Filial", value: "CODFILIAL"},
      {text: "Nº Pedido", value: "CODPEDIDO"},
      {text: "Cod. RCA", value: "CODUSUR"},
      {text: "Cód. Cli", value: "CODCLI"},
      //{ text: "CNPJ", value: "CNPJ" },
      {text: "Cliente", value: "CLIENTE"},
      {text: "Plano", value: "PLANOPAGAMENTO"},
      {text: "Plano Compl.", value: "PLANOPAGAMENTOCOMPL"},
      {text: "Tabela", value: "DESCRICAO"},
      {text: "Total", value: "VLRTOTAL", align: "end"},
      {text: "Status", value: "STATUS"},
      {text: "Dt. Pedido", value: "DTPEDIDO"},
      {text: "Dt. Envio", value: "DTENVIO"},
      {text: "Dt. Retorno", value: "DTRETORNO"},
      {text: "N° Ped. Winthor", value: "NUMPEDWINTHOR", align: "end"},
      //{ text: "Observação", value: "OBSERVACAO" },
      {text: "Posição Winthor", value: "POSICAOWINTHOR", align: "center"},
      {text: "Cód. Motivo Winthor", value: "CODMOTIVOWINTHOR"},
      {text: "Vlr. Atend. Winthor", value: "VLRATENDWINTHOR", align: "end"},
      {text: "Log", value: "STATUSLOG"},
    ] as any;
    // Excluir para Todos
    this.headerGrid.splice(1, 0, {
      text: "Excluir",
      value: "EXCLUIR",
      align: "center",
      sortable: false,
    } as never);
    if ((this.$auth.user() as any).PERMITIRLIBERAR === "S") {
      this.headerGrid.splice(2, 0, {
        text: "Liberar",
        value: "LIBERAR",
        align: "center",
        sortable: false,
      } as never);
      this.headerGrid.splice(3, 0, {
        text: "Rejeitar",
        value: "REJEITAR",
        align: "center",
        sortable: false,
      } as never);
    }
    this.exibirInfoInd = (this.$auth.user() as any).EXIBIRINFOIND === "S";
    if (this.exibirInfoInd) {
      this.headerGrid.splice(9, 0, {
        text: "Saldo",
        value: "VLRSALDO",
        align: "end",
      } as never);
    }
    this.itensGrid = [];
    this.modelPedido = null;
    this.entriesFilial = [];
    this.modelFilial = null;
    this.entriesFeira = [];
    this.entriesFornecedor = [];
    this.modelFornecedor = null;
    this.entriesCliente = [];
    this.modelCliente = null;
    this.entriesPlanoPag = [];
    this.modelPlanoPag = null;
    this.entriesEstado = [];
    this.modelEstado = null;
    this.keyFindGrid = "";
    this.vlrTotalPed = 0;
    this.vlrTotalSaldoPed = 0;
    this.dialogExcluirPedido = false;
    this.modelLiberado = true;
    this.modelAnalise = true;
    this.modelRejeitado = true;
    this.modelOrcamento = true;
    this.numPedidoGestCom = 0;
    this.numPedidoWinthor = 0;
    this.popularComboFilial();
    this.popularComboFeira();
    this.popularComboFornecedor();
    this.popularComboCliente();
    this.popularComboRCA();
    this.popularComboPlanoPag();
    this.popularComboEstado();
  }

  private popularComboFilial(): void {
    const token = "Bearer " + this.$auth.token();
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", token);

    fetch(
      this.baseURL +
      "pesquisas/filiais/" +
      (this.$auth.user() as any).MATRICULA,
      {
        method: "GET",
        headers: myHeaders,
        mode: "cors",
        cache: "default",
      }
    )
      .then((res) => res.json())
      .then((res) => {
        this.entriesFilial = res;
        this.entriesFilial.unshift({CODFILIAL: 0, FILIAL: '(TODOS)'} as never)
        this.modelFilial = Object.assign({}, this.entriesFilial[0]);
      })
      .catch((err) => {
        console.log(err);
      })
  }

  private popularComboFeira(): void {
    this.entriesFeira = [...((this.$auth.user() as any).FEIRAS as [])]
    this.entriesFeira.unshift({CODFEIRA: 0, DESCRICAO: '(TODOS)'} as never)
    this.modelFeira = Object.assign({}, this.entriesFeira[0]);
  }

  private popularComboFornecedor() {
    const token = "Bearer " + this.$auth.token();
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", token);

    // Lazily load input items
    fetch(
      this.baseURL +
      "pesquisas/fornecedores/" +
      (this.$auth.user() as any).MATRICULA,
      {
        method: "GET",
        headers: myHeaders,
        mode: "cors",
        cache: "default",
      }
    )
      .then((res) => res.json())
      .then((res) => {
        this.entriesFornecedor = res;
        if (this.entriesFornecedor.length > 0)
          this.modelFornecedor = Object.assign({}, this.entriesFornecedor[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  private popularComboCliente(): void {
    const token = "Bearer " + this.$auth.token();
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", token);

    // Lazily load input items
    fetch(
      this.baseURL + "pesquisas/clientes/" + (this.$auth.user() as any).CODUSUR,
      {
        method: "GET",
        headers: myHeaders,
        mode: "cors",
        cache: "default",
      }
    )
      .then((res) => res.json())
      .then((res) => {
        this.entriesCliente = res;
        this.entriesCliente.unshift({CODCLI: 0, DESCCOMPLETACLIENTE: '(TODOS)'} as never)
        this.modelCliente = Object.assign({}, this.entriesCliente[0]);
      })
      .catch((err) => {
        console.log(err);
      })

  }

  private popularComboRCA(): void {
    const token = "Bearer " + this.$auth.token();
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", token);
    fetch(
      this.baseURL +
      "pesquisas/rca/" +
      (this.$auth.user() as any).MATRICULA +
      "/" +
      (this.$auth.user() as any).CODUSUR,
      {
        method: "GET",
        headers: myHeaders,
        mode: "cors",
        cache: "default",
      }
    )
      .then((res) => res.json())
      .then((res) => {
        this.entriesRCA = res;
        this.entriesRCA.unshift({CODUSUR: 0, NOME: '(TODOS)'} as never)
        this.modelRCA = Object.assign({}, this.entriesRCA[0]);
      })
      .catch((err) => {
        console.log(err);
      })
  }

  private popularComboPlanoPag(): void {
    const token = "Bearer " + this.$auth.token();
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", token);
    fetch(
      this.baseURL +
      "pesquisas/planospagamentos",
      {
        method: "GET",
        headers: myHeaders,
        mode: "cors",
        cache: "default",
      }
    )
      .then((res) => res.json())
      .then((res) => {
        this.entriesPlanoPag = res;
        this.entriesPlanoPag.unshift({CODPLPAG: 0, PLANOPAGAMENTO: '(TODOS)'} as never)
        this.modelPlanoPag = Object.assign({}, this.entriesPlanoPag[0]);
      })
      .catch((err) => {
        console.log(err);
      })
  }

  private popularComboEstado() {
    const token = "Bearer " + this.$auth.token();
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", token);

    // Lazily load input items
    fetch(this.baseURL + "pesquisas/estados/", {
      method: "GET",
      headers: myHeaders,
      mode: "cors",
      cache: "default",
    })
      .then((res) => res.json())
      .then((res) => {
        this.entriesEstado = res;
        if (this.entriesEstado.length > 0)
          this.modelEstado = Object.assign({}, this.entriesEstado[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  get itemsFilial(): any[] {
    return this.entriesFilial.map((entry: any) => {
      const FILIAL =
        entry.CODFILIAL === 0 ?
          (entry.FILIAL.length > this.descriptionLimit
            ? entry.FILIAL.slice(0, this.descriptionLimit) + "..."
            : entry.FILIAL) :
          (entry.CODFILIAL + "-") +
          (entry.FILIAL.length > this.descriptionLimit
            ? entry.FILIAL.slice(0, this.descriptionLimit) + "..."
            : entry.FILIAL);

      return Object.assign({}, entry, {FILIAL});
    });

  }

  get itemsFeira(): any[] {
    return this.entriesFeira.map((entry: any) => {
      const FEIRA =
        entry.CODFEIRA +
        "-" +
        (entry.DESCRICAO.length > this.descriptionLimit
          ? entry.DESCRICAO.slice(0, this.descriptionLimit) + "..."
          : entry.DESCRICAO);

      return Object.assign({}, entry, {FEIRA});
    });
  }

  get itemsFornecedor(): any[] {
    return this.entriesFornecedor.map((entry: any) => {
      const FORNECEDORDESC =
        entry.FORNECEDOR.length > this.descriptionLimit
          ? entry.FORNECEDOR.slice(0, this.descriptionLimit) + "..."
          : entry.FORNECEDOR;

      return Object.assign({}, entry, {FORNECEDORDESC});
    });
  }

  get itemsCLiente(): any[] {
    return this.entriesCliente.map((entry: any) => {
      const DESCCOMPLETACLIENTE =
        entry.DESCCOMPLETACLIENTE.length > this.descriptionLimit
          ? entry.DESCCOMPLETACLIENTE.slice(0, this.descriptionLimit) + "..."
          : entry.DESCCOMPLETACLIENTE;

      return Object.assign({}, entry, {DESCCOMPLETACLIENTE});
    });
  }

  get itemsRCA(): any[] {
    return this.entriesRCA.map((entry: any) => {
      const NOME =
        entry.NOME.length > this.descriptionLimit
          ? entry.NOME.slice(0, this.descriptionLimit) + "..."
          : entry.NOME;

      return Object.assign({}, entry, {NOME});
    });
  }

  get itemsPlanoPag(): any[] {
    return this.entriesPlanoPag.map((entry: any) => {
      const PLANOPAGAMENTO =
        entry.PLANOPAGAMENTO.length > this.descriptionLimit
          ? entry.PLANOPAGAMENTO.slice(0, this.descriptionLimit) + "..."
          : entry.PLANOPAGAMENTO;

      return Object.assign({}, entry, {PLANOPAGAMENTO});
    });
  }

  private atualizarListaPedidos() {
    const token = "Bearer " + this.$auth.token();
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", token);

    let queryString = `matricula=${(this.$auth.user() as any).MATRICULA}`;

    if (this.modelFilial)
      if ((this.modelFilial as any).CODFILIAL !== 0) {
        queryString += `&codFilial=${(this.modelFilial as any).CODFILIAL}`;
      }

    if (this.modelFeira)
      if ((this.modelFeira as any).CODFEIRA !== 0) {
        queryString += `&codFeira=${(this.modelFeira as any).CODFEIRA}`;
      }

    if (this.modelFornecedor)
      if ((this.modelFornecedor as any).CODFORNEC !== 0) {
        queryString += `&codFornec=${(this.modelFornecedor as any).CODFORNEC}`;
      }

    if (this.modelCliente)
      if ((this.modelCliente as any).CODCLI !== 0) {
        queryString += `&codCli=${(this.modelCliente as any).CODCLI}`;
      }

    if (this.modelRCA)
      if ((this.modelRCA as any).CODUSUR !== 0) {
        queryString += `&codUsur=${(this.modelRCA as any).CODUSUR}`;
      }

    if (this.modelPlanoPag)
      if ((this.modelPlanoPag as any).CODPLPAG !== 0) {
        queryString += `&codPlPag=${(this.modelPlanoPag as any).CODPLPAG}`;
      }

    if (this.modelEstado)
      if ((this.modelEstado as any).UF !== "(TODOS)") {
        queryString += `&uf=${(this.modelEstado as any).UF}`;
      }

    if (this.modelPlanoPag)
      if ((this.modelPlanoPag as any).CODPLPAG !== 0) {
        queryString += `&codPlPag=${(this.modelPlanoPag as any).CODPLPAG}`;
      }

    if (this.numPedidoGestCom)
      if (this.numPedidoGestCom > 0) {
        queryString += `&numPedGestCom=${this.numPedidoGestCom}`;
      }
    if (this.numPedidoWinthor)
      if (this.numPedidoWinthor > 0) {
        queryString += `&numPedWinthor=${this.numPedidoWinthor}`;
      }

    if (this.modelLiberado) {
      queryString += `&statusLiberado=${this.modelLiberado}`;
    }

    if (this.modelAnalise) {
      queryString += `&statusAnalise=${this.modelAnalise}`;
    }

    if (this.modelRejeitado) {
      queryString += `&statusRejeitado=${this.modelRejeitado}`;
    }

    if (this.modelOrcamento) {
      queryString += `&statusOrcamento=${this.modelOrcamento}`;
    }

    if (this.dates.length === 2) {
        queryString += `&periodo1=${this.dates[0]}`;
        queryString += `&periodo2=${this.dates[1]}`;
    }
    if (this.dates.length == 1) {
      queryString += `&periodo1=${this.dates[0]}`;
    }

    fetch(
      this.baseURL +
      `pesquisas/pedidos/?${queryString}`,
      {
        method: "GET",
        headers: myHeaders,
        mode: "cors",
        cache: "default",
      }
    )
      .then((res) => res.json())
      .then((res) => {
        this.itensGrid = res;
        // Totalizar
        this.vlrTotalPed = 0;
        this.vlrTotalPedPL = 0;
        this.vlrTotalPedPA = 0;
        this.vlrTotalPedPR = 0;
        this.vlrTotalSaldoPed = 0;
        this.itensGrid.map((entry: any) => {
          this.vlrTotalPed += entry.VLRTOTAL;
          this.vlrTotalSaldoPed += entry.VLRSALDO;
          if (entry.STATUS === "PA") this.vlrTotalPedPA += entry.VLRTOTAL;
          else if (entry.STATUS === "PL") this.vlrTotalPedPL += entry.VLRTOTAL;
          else if (entry.STATUS === "PR") this.vlrTotalPedPR += entry.VLRTOTAL;
        });
        if (this.itensGrid.length > 0)
          this.tab = 1;
        else
          SendMsg.$emit("Atencao", "Nenhum registro localizado");


      })
      .catch((err) => {
        console.log(err);
      });
  }

  private editarItem(item: any) {
      // if (item.DTENVIO !== null ||
      //     item.STATUS === "PL") {
      //     SendMsg.$emit("Atencao", "Não pode ser editado!");
      //     return;
      // }
      const propEditando = (item.STATUS === "PL") ? "N": "S"
      const propCodPedido = item.CODPEDIDO;
      const propCodFeira = item.CODFEIRA;
      const propSeqFeira = item.SEQFEIRA;
      const propCodFilial = item.CODFILIAL;
      const propCodCli = item.CODCLI;
      const propCodPlPag = item.CODPLPAG;
      const propCodTabPrecoNegCab = item.CODTABPRECONEGCAB;
      const propCodUsur = item.CODUSUR;
      const propObservacao = item.OBSERVACAO;
      const propObservacaoLiberacao = item.OBSERVACAO_LIBERACAO;
      const propStatus = item.STATUS;
      const propCodPlPagCompl = item.CODPLPAGCOMPL;
      const propUtilizaPlanoCompl = item.UTILIZAPLANOCOMPL
      this.$router.replace({
          name: "pedidovendafeira",
          params: {
              propEditando: propEditando,
              propCodPedido: propCodPedido,
              propCodFeira: propCodFeira,
              propSeqFeira: propSeqFeira,
              propCodFilial: propCodFilial,
              propCodCli: propCodCli,
              propCodPlPag: propCodPlPag,
              propCodPlPagCompl: propCodPlPagCompl,
              propCodTabPrecoNegCab: propCodTabPrecoNegCab,
              propCodUsur: propCodUsur,
              propObservacao: propObservacao,
              propObservacaoLiberacao: propObservacaoLiberacao,
              propStatus: propStatus,
              propUtilizaPlanoCompl: propUtilizaPlanoCompl
          },
      });
  }

  private liberarRejeitarItem(item: any, status: string) {
    const token = "Bearer " + this.$auth.token();
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", token);

    // Lazily load input items
    fetch(this.baseURL + "pesquisas/liberarrejeitarpedido", {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({CODPEDIDO: item.CODPEDIDO, STATUS: status}),
      mode: "cors",
      cache: "default",
    })
      .then((res) => res.json())
      .then((res) => {
        if ((res.STATUS = "OK")) {
          item.STATUS = status;
          SendMsg.$emit("Atencao", "Pedido Liberado");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  private excluirPedido(item: any) {
    this.modelPedido = item;
    this.dialogExcluirPedido = true;
  }

  private excluirPedidoConfirmacao() {
    const token = "Bearer " + this.$auth.token();
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", token);

    // Lazily load input items
    fetch(this.baseURL + "pesquisas/excluirpedido", {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify({CODPEDIDO: (this.modelPedido as any).CODPEDIDO}),
      mode: "cors",
      cache: "default",
    })
      .then((res) => res.json())
      .then((res) => {
        if ((res.STATUS = "OK")) {
          this.itensGrid.splice(
            this.itensGrid.indexOf(this.modelPedido as never),
            1
          );
          SendMsg.$emit("Atencao", "Pedido Excluído");
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.dialogExcluirPedido = false;
        this.modelPedido = null;
      });
  }

  private frmValor(valor: any): string {
    if (valor === null || valor === undefined) return "0,00";
    else
      return valor.toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
  }

  private dataValida(date: any): string {
    if (date === null) return "";
    else {
      return new Date(date).toLocaleString();
    }
  }
}
