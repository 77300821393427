<template>
  <section>
    <vue-html2pdf
      :show-layout=false
      :float-layout=true
      :enable-download=true
      :preview-modal=false
      filename="fichaPedido"
      :paginate-elements-by-height=1000
      :pdf-quality=2
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      :manual-pagination=true
      :html-to-pdf-options="htmlToPdfOptions"
      @progress="onProgress($event)"
      @startPagination="startPagination()"
      @hasPaginated="hasPaginated()"
      @hasDownloaded="hasDownloaded($event)"
      ref="html2Pdf"
    >
      <pdf-content :pedido=pedido @domRendered="domRendered()"  slot="pdf-content" />
    </vue-html2pdf>

    <button v-if=show :disabled="isGenerating()" class="generate-btn"  @click="downloadPdf()">
      {{ isGenerating() ? 'Carregando...' : 'GERAR PDF' }}
    </button>

<!--    <section class="progress-container">-->
<!--      <div class="progress-bar">-->
<!--        <div-->
<!--          class="progress"-->
<!--          :style="`width: ${progress}%;`"-->
<!--        />-->
<!--      </div>-->

<!--      <section class="generating-label" v-if="progress !== 0">-->
<!--        <span class="label-title">-->
<!--          {{ progress === 100 ? 'PDF Generated' : 'Generating PDF' }}-->
<!--        </span>-->

<!--        <span class="label-progress">-->
<!--          {{ `${progress} %` }}-->
<!--        </span>-->
<!--      </section>-->
<!--    </section>-->
  </section>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf'
import PdfContent from "@/screens/Feira/Pedido/PdfContent.vue";

export default  {
  name: "fichaPedido",
  props: {
    show: {
      type: Boolean,
      default: false
    },
    pedido : {
      CABECALHO: {
        STATUS: '',
        FILIAL: '',
        FEIRA: '',
        TABELANEGOCIACAO: '',
        CLIENTE: '',
        RCA: '',
        PLANOPAGAMENTO: '',
        OBSERVACAO: '',
        PROPOSTANOVOPLANOPGTO: '',
      },
      ITENS: [{
        CODPROD: '',
        PRODUTO: '',
        MARCA: '',
        DESCONTO: 0,
        PRECOVENDA: 0,
        QUANTIDADE: 0,
        VALORTOTAL: 0
      }]
    }
  },
  data() {
    return {
      contentRendered: false,
      progress: 0,
      generatingPdf: false,
      pdfDownloaded: false,
    };
  },
  computed: {
    htmlToPdfOptions() {
      return {
        margin: 0,

        filename: "relatorio.pdf",

        image: {
          type: "jpeg",
          quality: 0.98,
        },

        enableLinks: true,

        html2canvas: {
          scale: 2,
          useCORS: true,
        },

        jsPDF: {
          unit: "in",
          format: 'a4',
          orientation: 'portrait',
        },
      };
    }
  },
  methods: {

    async downloadPdf() {
      this.$refs.html2Pdf.generatePdf();
    },

    onProgress(progress) {
      this.progress = progress;
      console.log(`PDF generation progress: ${progress}%`)
    },

    startPagination() {
      console.log(`PDF has started pagination`)
    },

    hasPaginated() {
      console.log(`PDF has been paginated`)
    },

    hasDownloaded(blobPdf) {
      console.log(`PDF has downloaded yehey`)
      this.pdfDownloaded = true
      console.log(blobPdf)
    },

    domRendered() {
      console.log("Dom Has Rendered");
      this.contentRendered = true;
    },

    onBlobGenerate(blob) {
      console.log(blob);
    },

    isGenerating() {
      return this.progress !== 0 && this.progress !== 100
    }
  },
  components: {
    VueHtml2pdf,
    PdfContent
  },
}
</script>

<style lang="scss">
.generate-btn {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-weight: 500;
  border: 0;
  padding: 6px 8px;
  border-radius: 4px;
  color: #fff;
  background: #243B55;
  text-align: center;
  cursor: pointer;
  letter-spacing: 0.0892857143em;
  line-height: 1.5;
  font-size: 0.75rem;
  text-transform: uppercase;
  transition-property: box-shadow, transform, opacity;
}

.progress-container {
  width: 25%;
  margin-top: 10px;

  .progress-bar {
    width: 100%;
    height: 10px;
    border: 1px solid #585858;
    border-radius: 5px;
    margin-bottom: 5px;

    .progress {
      width: 0%;
      height: 100%;
      transition: 0.3s;
      background: #2c3e50;
    }
  }
  .generating-label {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}

</style>
