<template>
  <section class="pdf-content">
    <section class="report-info">

      <section class="image-container">
        <img
          class="logo-image"
          src="../../../assets/logo.png"
          alt="Logo"
          ref="userImage"
        />
      </section>

      <section class="header-details">
        <h4 class="header-details-title">Espelho do Pedido de Venda</h4>

        <span class="detail-container">
          <span class="detail-label">Orçamento: </span>
          {{ pedido.CABECALHO.STATUS || '' }}
        </span>

        <span class="detail-container">
          <span class="detail-label">Filial:</span>
          {{ pedido.CABECALHO.FILIAL }}
        </span>

        <span class="detail-container">
          <span class="detail-label">Feira: </span>
          {{ pedido.CABECALHO.FEIRA }}
        </span>

        <span class="detail-container">
          <span class="detail-label">Tabela de Negociação: </span>
          {{ pedido.CABECALHO.TABELANEGOCIACAO }}
        </span>

        <span class="detail-container">
          <span class="detail-label">Cliente: </span>
          {{ pedido.CABECALHO.CLIENTE }}
        </span>

        <span class="detail-container">
          <span class="detail-label">RCA: </span>
          {{ pedido.CABECALHO.RCA }}
        </span>

        <span class="detail-container">
          <span class="detail-label">Plano de Pagamento: </span>
          {{ pedido.CABECALHO.PLANOPAGAMENTO }}
        </span>

        <span class="detail-container">
          <span class="detail-label">Observação: </span>
          {{ pedido.CABECALHO.OBSERVACAO }}
        </span>

        <span class="detail-container">
          <span class="detail-label">Proposta de novo Plano de Pgto: </span>
          {{ pedido.CABECALHO.PROPOSTANOVOPLANOPGTO }}
        </span>

      </section>
    </section>
    <section class="report-Itens">
      <section class="report-line"/>
      <h4 class="report-title">Itens do Pedido</h4>
      <table class="report-table">
        <thead>
        <tr>
          <th style="width: 100px;">Código</th>
          <th style="width: 600px;">Produto</th>
          <th style="width: 250px;">Marca</th>
          <th style="width: 100px;">% Desc.</th>
          <th style="width: 200px;">P. Venda Unit.</th>
          <th style="width: 100px;">Qtde</th>
          <th  style="width: 200px;">Valor Total</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(val, key) in pedido.ITENS" :key="key">
          <td class="report-table-item-text-align-left">{{ val.CODPROD }}</td>
          <td class="report-table-item-text-align-left">{{ val.PRODUTO }}</td>
          <td class="report-table-item-text-align-left">{{ val.MARCA }}</td>
          <td class="report-table-item-text-align-right">{{ val.DESCONTO.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</td>
          <td class="report-table-item-text-align-right">{{ val.PRECOVENDA.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</td>
          <td class="report-table-item-text-align-right">{{ val.QUANTIDADE }}</td>
          <td class="report-table-item-text-align-right">{{ val.VALORTOTAL.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</td>
        </tr>
        </tbody>
      </table>
      <section class="report-line"/>
      <h4 class="report-title">Totalizador do Pedido</h4>
      <section class="report-line"/>
      <section class="report-info">
        <section class="tot-details">
          <span class="tot-container">
              <span class="tot-label">Quantidade de Itens: </span>
              {{ getTotalItens() }}
          </span>
          <span class="tot-container">
              <span class="tot-label">Total do Pedido: </span>
              {{ getTotalPedido().toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}
          </span>
        </section>
      </section>
      <section class="report-line"/>
      <h4 class="report-title">Bonificação</h4>
      <table class="report-table">
        <thead>
        <tr>
          <th style="width: 100px;">Código</th>
          <th style="width: 600px;">Produto</th>
          <th style="width: 200px;">P. Venda Unit.</th>
          <th style="width: 100px;">Qtde</th>
          <th  style="width: 200px;">Valor Total</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(val, key) in pedido.BONIFICACAO" :key="key">
          <td class="report-table-item-text-align-left">{{ val.CODPROD }}</td>
          <td class="report-table-item-text-align-left">{{ val.PRODUTO }}</td>
          <td class="report-table-item-text-align-right">{{ val.VALOR_UNITARIO.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</td>
          <td class="report-table-item-text-align-right">{{ val.QUANTIDADE }}</td>
          <td class="report-table-item-text-align-right">{{ val.VALOR_TOTAL.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</td>
        </tr>
        </tbody>
      </table>
      <section class="pdf-item">
      <section class="report-line"/>
      <h4 class="report-title">Totalizador das Bonificação</h4>
      <section class="report-line"/>
      <section class="report-info">
        <section class="tot-details">
          <span class="tot-container">
              <span class="tot-label">Quantidade de Itens: </span>
              {{ getTotalItensBonificacao() }}
          </span>
          <span class="tot-container">
              <span class="tot-label">Total da Bonificação: </span>
              {{ getTotalBonificacao().toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}
          </span>
        </section>
      </section>
      </section>
      <section class="pdf-item">
      <section class="report-line"/>
      <h4 class="report-title">Brindes</h4>
      <table class="report-table">
        <thead>
        <tr>
          <th style="width: 100px;">Código</th>
          <th style="width: 600px;">Produto</th>
          <th style="width: 200px;">P. Venda Unit.</th>
          <th style="width: 100px;">Qtde</th>
          <th  style="width: 200px;">Valor Total</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(val, key) in pedido.BRINDES" :key="key">
          <td class="report-table-item-text-align-left">{{ val.CODBRINDE }}</td>
          <td class="report-table-item-text-align-left">{{ val.BRINDE }}</td>
          <td class="report-table-item-text-align-right">{{ val.VALOR_UNITARIO.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</td>
          <td class="report-table-item-text-align-right">{{ val.QUANTIDADE }}</td>
          <td class="report-table-item-text-align-right">{{ val.VALOR_TOTAL.toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}</td>
        </tr>
        </tbody>
      </table>
      <section class="report-line"/>
      <h4 class="report-title">Totalizador do Brindes</h4>
      <section class="report-line"/>
      <section class="report-info">
        <section class="tot-details">
          <span class="tot-container">
              <span class="tot-label">Quantidade de Brindes: </span>
              {{ getTotalItensBrindes() }}
          </span>
          <span class="tot-container">
              <span class="tot-label">Total da Bonificação: </span>
              {{ getTotalBrindes().toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }}
          </span>
        </section>
      </section>
      </section>
  </section>
  </section>
</template>

<script>

export default {
  props: {
    pedido : {
      CABECALHO: {
        STATUS: String,
        FILIAL: String,
        FEIRA: String,
        TABELANEGOCIACAO: String,
        CLIENTE: String,
        RCA: String,
        PLANOPAGAMENTO: String,
        OBSERVACAO: String,
        PROPOSTANOVOPLANOPGTO: String,
      },
      ITENS: [{
        CODPROD: String,
        PRODUTO: String,
        MARCA: String,
        DESCONTO: Number,
        PRECOVENDA: Number,
        QUANTIDADE: Number,
        VALORTOTAL: Number
      }],
      BONIFICACAO: [{
        CODPROD: String,
        PRODUTO: String,
        QUANTIDADE: Number,
        VALOR_UNITARIO: Number,
        VALOR_TOTAL: Number
      }],
      BRINDES: [{
        CODBRINDE: String,
        BRINDE: String,
        QUANTIDADE: Number,
        VALOR_UNITARIO: Number,
        VALOR_TOTAL: Number
      }]
    }
  },
  data() {
    return {
      chartOptions: {
        dataLabels: {
          enabled: false,

          style: {
            colors: ["#fff"],
          },
        },

        stroke: {
          curve: "smooth",
        },
      },
    };
  },
  methods: {
    getTotalItens() {
      if (!this.pedido.ITENS) return 0;
      return this.pedido.ITENS.length;
    },
    getTotalPedido() {
      return this.pedido.ITENS.map((item) => item.VALORTOTAL).reduce((acc, cur) => acc + cur, 0);
    },
    getTotalItensBonificacao() {
      if (!this.pedido.BONIFICACAO) return 0;
      return this.pedido.BONIFICACAO.length;
    },
    getTotalBonificacao() {
      return this.pedido.BONIFICACAO.map((item) => item.VALOR_TOTAL).reduce((acc, cur) => acc + cur, 0);
    },
    getTotalItensBrindes() {
      if (!this.pedido.BRINDES) return 0;
      return this.pedido.BRINDES.length;
    },
    getTotalBrindes() {
      return this.pedido.BRINDES.map((item) => item.VALOR_TOTAL).reduce((acc, cur) => acc + cur, 0);
    }
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.$emit("domRendered");
      }, 1000);
    });
  },
};
</script>

<style lang="scss" scoped>
.pdf-content {
  width: 100%;
  background: #fff;
  font-family: "Roboto", sans-serif;

  .report-info {
    display: flex;
    flex-direction: row;
    padding: 6px 6px 0px;

    .image-container {
      width: 140px;
      height: 80px;
      overflow: hidden;
      position: relative;
      margin-right: 25px;
      border: 0px solid #dadada;

      .logo-image {
        height: 100%;
        min-width: 100%;
      }
    }

    .header-details {
      display: flex;
      flex-direction: column;
      flex-shrink: 0;

      .header-details-title {
        margin-bottom: 6px;
        flex-shrink: 0;
      }

      .detail-container {
        font-size: 10px;
        flex-shrink: 0;

        &:not(:last-child) {
          margin-bottom: 6px;
        }

        .detail-label {
          font-weight: 600;
          margin-right: 5px;
          font-size: 10px;
        }
      }
    }

    .tot-details {
      display: flex;
      flex-direction: column;
      flex-shrink: 0;

      .tot-container {
        font-size: 10px;
        flex-shrink: 0;

        &:not(:last-child) {
          margin-bottom: 6px;
        }

        .tot-label {
          font-weight: 600;
          margin-right: 4px;
          font-size: 10px;
        }
      }
    }

  }

  .report-title {
    flex-shrink: 0;
    font-weight: 600;
    margin-top: 4px;
    margin-bottom: 4px;
    font-size: 12px;
    text-align: center;
  }

  .report-Itens {
    display: flex;
    flex-direction: column;
    padding: 20px 20px 0px;
    .report-line {
      padding: 4px 4px 0px;
      border-bottom: 1px solid #000;
      width: 100%;
      display: block;
    }
  }
  .report-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 4px;
  }

  .report-table th {
    border: 1px solid #000;
    padding: 1px;
    text-align: center;
    font-size: 10px;
    background-color: #f2f2f2;
  }

  .report-table td {
    padding-left: 8px;
    padding-right: 8px;
    font-size: 10px;
  }
  .report-table-item-text-align-left {
    text-align: left;
  }
  .report-table-item-text-align-right {
    text-align: right;
  }

  .pdf-item {
    page-break-inside: avoid;
  }

}
</style>
