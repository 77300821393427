
import { Component, Vue } from "vue-property-decorator";
import SendMsg from "@/lib/sendMsg";

@Component({
  components: {},
})
export default class App extends Vue {
  private activeSnackbar = false;
  private msg = "";
  private timeout = 3500;
  private loaded = false;

  mounted() {
    // Exibindo Snackbar
    SendMsg.$on("Atencao", (value: string) => {
      this.msg = value;
      this.activeSnackbar = true;
    });
    this.loaded = true;
  }

  created() {
    this.$auth.ready();
  }
}
